<template>
    <div class="education-wrapper">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item><span style="font-weight: 600; color: #303133 !important;">运营推广管理</span></el-breadcrumb-item>
            <el-breadcrumb-item>搜索广告</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="education-header">
            <IndustrySelection @selectListChange="selectListChange" :selectList="selectList" text="行业分类："></IndustrySelection>
            <div>
                <el-button class="btn-blue" @click="sendBtn" style="display: inline-block;">发送</el-button>
                <el-button class="btn-blue" @click="addBtn" style="display: inline-block;">创建搜索数据</el-button>
            </div>
        </div>
        <el-table ref="multipleTable" :data="searchAdList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}">
            <el-table-column type="selection" width="50" align="center"></el-table-column>
            <el-table-column prop="name" align="center" label="搜索广告名称"></el-table-column>
            <el-table-column prop="remark" align="center" label="备注"></el-table-column>
            <el-table-column prop="category_name" align="center" label="行业分类"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" @click="copyTemplate(scope.row)">复制</el-link>
                    <el-link :underline="false" class="link-blue" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link :underline="false" type="danger" @click="delBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { copySearchADTemplateAPI, sendSearchADTemplateAPI } from "../../../utils/apis"
    import IndustrySelection from "../../admin/IndustrySelection";

    export default {
        name: "SearchAds",
        components: {
            IndustrySelection
        },
        data() {
            return {
                //搜索广告列表
                searchAdList: [],
                //分页
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                selectList: [],
                selectVal: void 0,
            }
        },
        mounted() {
            this.getSearchAdList();
            this.getCategoryConfig();
        },
        methods: {
            async sendBtn(){
                let id =this.$refs.multipleTable.selection.map((item) => item.id);
                if(id && id.length > 0){
                  const result = await this.$common.sendData(sendSearchADTemplateAPI, { ids: id });
                  result && this.getSearchAdList(this.selectVal);
                }else{
                  this.$message.error("请勾选需要发送的数据！")
                }
            },
            selectListChange(id) {
                this.selectVal = id;
                this.getSearchAdList(id);
            },
            // 获取行业分类
            getCategoryConfig() {
                this.$http.axiosGetBy(this.$api.categoryList, {}, (res) => {
                    if (res.code === 200) {
                        this.selectList = res.data;
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            },
            async copyTemplate(item) {
                const valid = await this.$common.copyTemplatePack(copySearchADTemplateAPI, { id: item.id });
                valid && this.getSearchAdList(this.selectVal);
            },
            //获取搜索广告列表
            getSearchAdList(id) {
                let param = {
                    type: 'page',
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                    category_id: id ? id : ''
                }
                this.$http.axiosGetBy(this.$api.searchAdsList, param, (res) => {
                    if (res.code === 200) {
                        this.searchAdList = res.data.data;
                        this.listPages.total = res.data.total;
                        if (this.listPages.total !== 0 && this.searchAdList.length === 0) {
                            this.listPages.currentPageNum--;
                            this.getSearchAdList(this.selectVal);
                        }
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //创建
            addBtn() {
                this.$router.push('/admin/operatedata/searchads/create');
            },
            //编辑
            editBtn(row) {
                this.$router.push({
                    path: '/admin/operatedata/searchads/create',
                    query: {
                        id: row.id
                    }
                })
            },
            //删除
            delBtn(id) {
                this.$confirm('是否删除该搜索广告，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.delSearchData, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getSearchAdList(this.selectVal);
                        } else {
                            this.$message.warning(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val;
                this.getSearchAdList(this.selectVal);
            },
        }
    }
</script>

<style scoped lang="scss">
    .education-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .education-header {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: 24px;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
</style>